import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { GlobeIcon } from '@/atoms/Icons/GlobeIcon'
import { useToast } from '@/molecules/Toast'

export const TvAuthToast: React.FC<object> = () => {
  const { showToast } = useToast()
  const { query } = useRouter()

  useEffect(() => {
    const TvAuthMessage = 'Congratulations! Your device is now connected.'
    if (query['tv-post-auth'] === 'True') {
      showToast(TvAuthMessage, {
        showInMillis: 10000,
        icon: <GlobeIcon color="white" />,
      })
    }
  }, [showToast, query])

  return null
}
